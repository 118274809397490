<!--
 * @Author       : JiangChao
 * @Date         : 2024-07-18 13:38:40
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-09-27 13:24:05
 * @Description  : 添加成员到组织
-->

<template>
  <el-dialog
    :visible.sync="dialogFormVisible"
    width="50%"
    :append-to-body="true"
  >
   <template slot="title">
      <div class="font-heavy" style="font-size: 18px;color: #303133">
        {{ $t("add_member_to_the_organization") }}
      </div>
    </template>
    <div class="container">
      <el-form
        :model="form"
        status-icon
        ref="resetRef"
        label-width="130px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('account')">
          <el-input
            :placeholder="$t('phone_email')"
            clearable
            v-model="form.subject"
            autocomplete="off"
            class="input-select-custom-width"
          >
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('role')">
          <el-select
            v-model="form.limits"
            :placeholder="$t('select_role')"
            class="input-select-custom-width"
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in permissions"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">{{
        $t("cancel")
      }}</el-button>
      <el-button type="primary" @click="handelOk">{{
        $t("confirm")
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addToOrgApi } from "@/api/api.js";

export default {
  name: "",
  data() {
    return {
      form: {},
      dialogFormVisible: false,
      orgId: "",
      permissions: [
        { key: 0, value: "Member" },
        { key: 1, value: "Admin" },
      ], //授权类型 0：查看 1：编辑
    };
  },
  methods: {
    showDialog(id) {
      console.log("id: ", id);
      this.form = {};
      this.dialogFormVisible = true;
      this.orgId = id;
    },

    handelOk() {
      // if (!this.form.limits) {
      //   this.$message.error("请选择权限");
      //   return;
      // }
      let params = {
        ...this.form,
        // invitationCode: this.invitationCode,
        orgId: this.orgId,
      };
      addToOrgApi(params).then((r) => {
        console.log("=====", r);
        this.$message.success(this.$t("operation_successful"));

        this.dialogFormVisible = false;
        this.$emit("refreshMember");
      });
    },
  },
  props: {},
  components: {},
  computed: {
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },
  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },

  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
  },
  created() {},
};
</script>

<style scoped lang="less">
/* styles.css */
.input-select-custom-width {
  width: 400px;
}
</style>
